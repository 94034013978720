<template>
    <div class="body_wrap">
        <div class="line"></div>
        <div style="background:#f5f5f5;">
            <section>
                <el-steps :active="active" finish-status="success" align-center>
                    <!-- <el-step :title="orderEntry === 0 ? '查看购物车' : orderEntry * 1 === 1 ? '选择产品' : '选择商家'"></el-step> -->
                    <el-step title="选择商家"></el-step>
                    <el-step title="确认订单信息"></el-step>
                    <el-step title="成功提交订单"></el-step>
                </el-steps>
                <Address @selected="selectAddress" @sendInfo="change"/>
                <div class="product-list">
                    <el-row class="product-header" style="margin:10px 0">
                        <el-col :span="24">
                            <span class="product-header-tip">产品清单</span>
                        </el-col>
                    </el-row>
                    <!-- <el-row class="product-table-head">
                        <el-col :span="6">
                            <div>产品信息</div>
                        </el-col>
                        <el-col :span="6" style="display:flex;justify-content: center">
                            <div>需求名称</div>
                        </el-col>


                        <el-col :span="6" style="display:flex;justify-content: center">
                            <div >工期</div>
                        </el-col>
                        <el-col :span="6" style="display:flex;justify-content: center">
                            <div>报价</div>
                        </el-col>
                    </el-row> -->

                    <el-row class="product-body" v-for="(item, index) in bidInfo" :key="index">
                        <el-row class="product-shop">
                            <el-col :span="24">
                                <div class="product-shop-name" v-text="item.storeName"></div>
                            </el-col>
                        </el-row>
                        <div class="product-items-wrap">
                            <el-col :span="12">
                                <el-row>
                                    <el-col :span="6">
                                        <img src="https://imag.kqwad.com/upload/f6e08a1acfcc489aa35de694675265cc.png"
                                             class="needImg">
                                    </el-col>
                                    <el-col :span="18">
                                        <p :title="item.name" class="product-name" v-text="item.name"></p>
                                        <!--                      <p>{{item.sn}}</p>-->
                                        <!--                      <p>需求订单</p>-->
                                    </el-col>
                                </el-row>
                            </el-col>
                            <!-- <el-col :span="6">
                                <div class="product-price" style="display:flex;justify-content: center">{{item.time}}天</div>
                            </el-col> -->
                            <!-- <el-col :span="4">
                                <div class="product-num" v-text="1"></div>
                            </el-col> -->
                            <el-col :span="12" style="display:flex;justify-content: center;color:#ff425e">
                                <div
                                        class="product-total-price"
                                >￥{{item.price |price}}元
                                </div>
                            </el-col>
                        </div>
                    </el-row>
                    <el-row v-if="0">
                        <div class="receipt">
                            <el-row>
                                <el-col :span="24">
                                    <el-checkbox v-model="checked">需要开票</el-checkbox>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="24" :offset="1">
                                    <span class="receipt-label">抬头类型：</span>
                                    <el-radio v-model="radio" label="1">企业</el-radio>
                                    <el-radio v-model="radio" label="2">个人</el-radio>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="24" :offset="1">
                                    <span class="receipt-label">发票类型：</span>
                                    <el-radio v-model="radio" label="1">增值税专用发票</el-radio>
                                    <el-radio v-model="radio" label="2">增值税普通发票</el-radio>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="24" :offset="1">
                                    <span class="receipt-label">发票信息</span>
                                </el-col>
                            </el-row>
                            <div v-show="radio === 1">
                                <el-row :gutter="20">
                                    <el-col :span="24" :offset="1" class="flex align-center">
                                        <span class="receipt-label">识别码：</span>
                                        <el-input type="text" placeholder="请输入识别码"></el-input>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="24" :offset="1" class="flex align-center">
                                        <span class="receipt-label">注册地址：</span>
                                        <el-input type="text" placeholder="请输入注册地址"></el-input>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="24" :offset="1" class="flex align-center">
                                        <span class="receipt-label">注册电话：</span>
                                        <el-input type="text" placeholder="请输入注册电话"></el-input>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="24" :offset="1" class="flex align-center">
                                        <span class="receipt-label">开户银行：</span>
                                        <el-input type="text" placeholder="请输入开户银行"></el-input>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="24" :offset="1" class="flex align-center">
                                        <span class="receipt-label">银行账号：</span>
                                        <el-input type="text" placeholder="请输入银行账号"></el-input>
                                    </el-col>
                                </el-row>
                            </div>
                            <div v-show="radio ==2">
                                <el-row :gutter="20">
                                    <el-col :span="24" :offset="1" class="flex align-center">
                                        <span class="receipt-label">抬头名称：</span>
                                        <el-input type="text" placeholder="请输入抬头名称"></el-input>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </el-row>
                    <el-row class="product-footer">
                        <p>给卖家留言：</p>
                        <el-col :span="12">
                            <el-input type="textarea" :rows="4" placeholder="请输入内容(0-100)"
                                      v-model="param.remark"></el-input>
                        </el-col>
                    </el-row>
                </div>
                <el-row class="product-submit" :gutter="20">
                    <el-col :span="10" :offset="10">
                        共1件产品，&nbsp;&nbsp; 产品总价：￥{{price}}&nbsp;&nbsp; 订单总金额
                        <span>￥{{price}}</span>
                    </el-col>
                    <el-col
                            class="product-submit-btn"
                            :span="4"
                            style="cursor:pointer;"
                            @click.native="submitOrder"
                    >提交订单
                    </el-col>
                </el-row>
                <el-row class="tip-warp">
                    <el-col :span="24">温馨提示：</el-col>
                    <el-col :span="24">
                        <span class="zeng f12">增</span>
                        提交订单并确认收货成功后，将同时赠送您
                        <span class="jf">{{jf}}积分</span> ，可用于孔雀有礼
                        <a href="###">积分商城</a>礼品兑换。
                    </el-col>
                </el-row>
            </section>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>

    import Address from '../.././order/address/index';
    import {mapMutations, mapGetters, mapActions} from 'vuex'
    import {getUserAddress, delAddress} from '../../../api/api'

    export default {
        name: 'orderNeed',
        components: {
            Address
        },
        data() {
            return {
                checked1:false,
                checked2:false,
                jf:"",
              price:"",
                bidInfo: [],//需求订单详情
                active: 1,
                radio: 1,
                checked: true,
                totalNumber: 0,
                totalPrice: 0,
                score: 0,
                storeList: [],
                param: {
                    billType: 0,
                    cartTTos: [],
                    headType: 0,
                    invoiceId: -1,
                    invoiceTitle: '',
                    number: 0,
                    remark: '',
                    userConsigneeId: ''
                }
            }
        },
        computed: {
            ...mapGetters(['orderEntry', 'cartItems', 'product', 'store'])
        },
        methods: {
            change(value,value1){
                this.checked1=value;
                this.checked2=value1;
            },
            ...mapActions(['queryCartList']),
            ...mapMutations({savePay: 'PAY_INFO'}),
            selectAddress(e) {
                const {id, selected} = e
                if (selected) this.param.userConsigneeId = id
                else this.param.userConsigneeId = ''
            },
            calcPrice() {
                let price = 0
                let total = 0
                this.cartItems.forEach(item => {
                    price += item.cartItemList.reduce((p, c) => {
                        return p + (c.checked && c.productPrice * c.productQuantity)
                    }, 0)
                    total += item.cartItemList.reduce((p, c) => {
                        return p + (c.checked ? 1 : 0)
                    }, 0)
                })
                this.totalNumber = total
                this.totalPrice = price
                this.score = Math.floor(this.totalPrice)
            },
            getMes() {
                var _this = this;
                let bidId = this.$route.query.bidId;
                this.$http.get("tOrder/bidOrder?bidSn=" + bidId).then(res => {
                    console.log(res.data.data)
                    _this.bidInfo = res.data.data.item;
                    _this.price=res.data.data.item[0].price;
                    _this.jf = Math.floor( _this.price);
                });
            },
            submitOrder() {
                 if (!this.checked1 && !this.checked2) {
                      this.$message({
                        message: '请勾选收货配送方式!',
                        type: 'error'
                    })
                    return
                 }
                 if (this.checked2 && this.param.userConsigneeId=="") {
                     this.$message({
                        message: '请选择收货地址!',
                        type: 'error'
                    })
                    return
                 }
                // if (this.param.userConsigneeId === '') {
                //     this.$message({
                //         message: '请选择收货地址',
                //         type: 'error'
                //     })
                //     return
                // }
                const param = {
                    billType: 0,
                    headType: 1,
                    invoiceId: '',
                    invoiceTitle: '',
                    number: 1,
                    price: this.bidInfo[0].price,
                    remark: this.param.remark,
                    storeId: this.bidInfo[0].storeId,
                    sn: this.bidInfo[0].sn,
                    userConsigneeId: this.param.userConsigneeId
                };

                this.$http.post('tOrder/addOrder', param).then(res => {
                    const {code, data} = res.data
                    if (code * 1 === 0) {
                        this.queryCartList()
                        this.savePay(data[0])
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: '下单成功',
                            onClose: () => {
                                const {subject} = data[0]
                                this.$router.push({name: 'paystatus', params: {orderSn: subject}})
                            }
                        })
                    }
                });
            }
        },
        mounted() {
            this.getMes();
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../assets/scss/common';

    @mixin icon($m) {
        $url: '../../assets/image/' + $m;
        @include bgImage($url);
    }

    section {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto 0;
        padding-top: 20px;
        // overflow: hidden;
        clear: both;

        .product-list {
            margin-top: 20px;
            background-color: #fff;
            padding: 20px;
            box-sizing: border-box;

            .product-header {
                .product-header-tip {
                    padding-left: 10px;
                    border-left: 3px solid $color168;
                }
            }

            .product-table-head {
                margin: 10px 0;
                background-color: #f5f5f5;
                padding: 10px;
            }

            .product-body {
                border: 1px solid #e6e6e6;

                .product-shop {
                    padding: 10px;
                    background: #f5f5f5;
                }

                .product-items-wrap {
                    border-bottom: 1px solid #e6e6e6;
                    padding: 0 10px;
                    .product-item {
                        margin: 20px;

                        img {
                            width: 89px;
                            height: 89px;
                        }

                        .product-name {
                            width: 220px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        p {
                            font-size: 12px;
                            color: #999;
                            line-height: 23px;

                            &.product-name {
                                font-size: 15px;
                                color: #333;
                            }
                        }

                        .product-price {
                            text-align: center;
                            font-size: 15px;
                            color: #333;
                        }

                        .product-num {
                            font-size: 14px;
                            color: #333;
                        }

                        .product-total-price {
                            color: #ff0036;
                            font-size: 15px;
                        }
                    }
                }
            }

            // 发票
            .receipt {
                padding: 20px;

                .el-row {
                    margin-bottom: 10px;

                    .receipt-label {
                        font-size: 14px;
                        color: #666;
                        min-width: 90px;

                        + .el-input {
                            max-width: 300px;
                        }
                    }
                }
            }

            .product-footer {
                box-sizing: border-box;

                p {
                    margin: 20px 0;
                }

                .remark-num-wrap {
                    position: relative;
                    .remark-num {
                        position: absolute;
                        bottom: -55px;
                        left: 18px;
                    }
                }
            }
        }

        .product-submit {
            margin-top: 20px;
            background-color: #fff;
            margin: 20px 0;
            height: 50px;
            box-sizing: border-box;
            line-height: 50px;
            padding: 0;
            span {
                color: red;
            }

            .product-submit-btn {
                color: #fff;
                text-align: center;
                background-color: #d82229;
            }
        }

        .tip-warp {
            padding: 5px;
            font-size: 14px;

            .el-col {
                margin-bottom: 10px;
            }

            .zeng {
                width: 40px;
                padding: 2px 5px;
                display: inline-block;
                color: #fff;
                text-align: center;
                background-color: #d82229;
            }

            .jf {
                color: red;
            }

            a {
                color: #d82229;
            }
        }
    }

    .product-price, .product-num, .product-total-price, .product-name {
        height: 60px;
        display: flex;
        text-align: center;
        align-items: center;
    }

    .needImg {
        display: block;
        margin-left: 20px;
        width: 50px;
        height: 50px;
        margin-top: 5px;
    }
</style>
