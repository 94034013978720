<template>
  <div class="order-address">
   
    <el-row class="address-header">
      <el-col :span="20">
        <span class="address-header-tip">收货信息</span>
        <div class="isDelivery">
          <el-checkbox v-model="checked1" @change="handleCheck1(0)">无需配送</el-checkbox>
          <el-checkbox v-model="checked2" @change="handleCheck1(1)">需要配送</el-checkbox>

        </div>
         
        <!--&nbsp;&nbsp;|&nbsp;
        <span>
          请在
          <i class="color168" v-text="timeStr"></i>内提订单，下单后你另有30分钟的支付时间
        </span>-->
      </el-col>
      <el-col :span="4" class="address-all-btn" v-show="addressList.length > 4">
        <span style="flex: 1;"></span>
        <el-button type="text" @click="onToggleAddress">
          <i class="el-icon-arrow-down"></i>
          全部地址
        </el-button>
      </el-col>
    </el-row>
    <div class="address-content" v-show="isDelivery">
      <Item
        v-for="(item, index) in addressList"
        :selected="item.selected"
        :key="index"
        :data="item"
        @selected="val => onSelected(item, val)"
        @delete="onDelete($event)"
        @update="onUpdate"
        @setDefault="onSetDefault"
      />
      <div @click="onAdd" class="address-item address-item_no">
        <i class="add_address">+</i>
        <p>添加地址</p>
      </div>
    </div>
    <Add ref="address" :params="addParmas"/>
  </div>
</template>
<script>
import Item from './Item.vue'
import Add from './add.vue'
import mixins from '@/mixins'
export default {
  mixins: [mixins],
  components: {
    Item,
    Add
  },
  data() {
    return {
      checked1:false,
      checked2:false,
      isDelivery:false,
      togger: false,
      addParmas: {
        visible: false
      },
      list: []
    }
  },
  mounted() {
    this.countDown()
    this.queryList()
    // this.checked=true;
  },
  computed: {
    addressList() {
      const nList = this.togger ? this.list : this.list.slice(0, 3)
      return nList
    }
  },
  methods: {
    handleCheck1(i){
      if(!i){
        this.checked1=true;
        this.checked2=false;
      }else{
          this.checked1=false;
          this.checked2=true;
      }
      if (this.checked1) {
          this.isDelivery=false;        
      }else{
          this.isDelivery=true;        
      }

      //向父组件传值
      this.$emit('sendInfo',this.checked1,this.checked2)
    },

    queryList() {
      this.$http.get('userConsignee/getSiteByUserId').then(res => {
        const { data } = res.data
        this.list = data
      })
    },
    onToggleAddress() {
      this.togger = !this.togger
    },
    onSelected(item, state) {
      this.list = this.list.map(i => {
        if (item.id !== i.id) {
          i.selected = false
        }
        return i
      })
      this.$set(item, 'selected', state)
      this.$emit('selected', item)
    },
    onDelete(id) {
      this.$confirm('是否删除地址?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        this.$http.get('userConsignee/deleteById', { params: { userConsigneeId: id } }).then(res => {
          this.$message({
            message: '删除成功',
            type: 'success',
            onClose: () => {
              this.queryList()
            }
          })
        })
      })
    },
    onUpdate(item) {
      this.addParmas = {
        visible: true,
        isEdit: true,
        item,
        confirm: val => {
          this.addParmas.visible = false
          this.queryList()
        }
      }
    },
    onSetDefault(id) {
      this.$http.get('userConsignee/updateIsDefault', {params: {userConsigneeId: id}}).then(res => {
        this.$message({
          message: '修改成功',
          type: 'success',
          onClose: () => {
            this.queryList()
          }
        })
      });
    },
    onAdd() {
      this.addParmas = {
        visible: true,
        confirm: val => {
          this.addParmas.visible = false
          this.queryList()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/common.scss';
@mixin icon($m) {
  $url: '../../../assets/image/' + $m;
  @include bgImage($url);
}

.order-address {
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 30px;
  .address-header {
    .address-header-tip {
      padding-left: 10px;
      border-left: 3px solid $color168;
    }
    .isDelivery{
      height: 60px;
      display: flex;
      align-items: center;
    }
  }
  .address-content {
    margin-top: 10px;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    margin-left: 0.5%;

    .address-item_no {
      background: none;
      text-align: center;
      color: $color999;
      font-size: 16px;
      .add_address {
        background:#d82229 ;
        width: 44px;
        height: 44px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        display: block;
        font-style: normal;
        margin: 0 auto;
        margin-top: 40px;
        margin-bottom: 5px;
        border-radius: 50%;
        font-size: 35px;
      }
    }
  }

  .address-all-btn {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    .el-button {
      width: 80px;
      margin-right: 10px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
    }
  }
}
</style>