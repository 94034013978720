<template>
  <!-- 商家信息组件 产品详情页，退款页面公共组件 -->
  <div class="bussineInfo">
    <div class="info">
      <div class="title">商家信息</div>
      <div class="content_1">
        <div class="company flex align-center">
          <span v-text="store.storeName"></span>
          <a v-if="0"></a>
        </div>
        <div class="btn">
          <span @click="clickHandler(1)">进入店铺</span>
          <span @click="clickHandler(2)" v-show="1" >已收藏店铺</span>
        </div>
      </div>
      <h4 style="text-align: left;padding-left: 15px;margin-top:15px;">线下地址</h4>
      <p style="text-align: left;padding:0 15px;" class="flex-y f12">
        <span v-text="store.address" class="adressa"></span>
        <span v-text="store.detailAddress" class="adressa"></span>
      </p>
      <h4 style="text-align: left;padding-left: 15px">联系方式</h4>
      <!-- <a class="phone" :href="'tel:'+store1.mobile">
        <a></a>电话联系
      </a> -->
      <a class="phone" @click="dialogVisible = true">
        <a></a>电话联系
      </a>

      <el-dialog title="" :visible.sync="dialogVisible">
        <div class="popTop">
          <p class="storeName">{{store.storeShortName}}</p>
        <div class="storeMobile">
          <div>
            <span>
              <img src="../../assets/image/phone.png" alt="" align="middle">
            </span>
            <span>{{store.mobile}}</span>
          </div>
          <div class="streFooter">
            注：为了您的交易安全，请走孔雀有礼线上交易如遇可疑商家，可及时向平台投诉举报！
          </div>
        </div>
        </div>


        <!-- <el-table :data="gridData">
          <el-table-column property="date" label="日期" width="150"></el-table-column>
          <el-table-column property="name" label="姓名" width="200"></el-table-column>
          <el-table-column property="address" label="地址"></el-table-column>
        </el-table> -->
      </el-dialog>


      <div class="callBox" v-for="(item, index) in callLists" :key="index">
        <span>客服-{{item.nickName}}:</span>
        <div class="callRight">
          <i class="el-icon-service"></i>
           <a class="server" target="_blank" :href="'http://wpa.qq.com/msgrd?v=3&uin='+item.qq+'&site=qq&menu=yes'">QQ交谈</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  props: ['storeId'], //父组件传递的店铺id,传递了，卡片才显示
  data() {
    return {
       gridData: [{
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }],
        dialogVisible: false,
      store: {},
      callLists: [],

    }
  },
  mounted() {
    // console.log(this.storeId,33)
    this.query()
  },
  methods: {
    ...mapMutations({saveStore: 'STORE_INFO'}),
    query() {
      this.$http.get('store/info', { params: { storeId: this.storeId } }).then(res => {
        const {data} = res.data
        this.store = data
        const {storeCustomerServiceList} = data
        this.callLists = storeCustomerServiceList || []

        this.saveStore(this.store)
        // console.log(this.store)
      })
    },
    clickHandler(index) {
      const {id} = this.store
      index ===1 ? this.$router.push({name: 'shopPage', params: {id}}) : '进入店铺';
      index ===2 ? this.$router.push({name: 'shopCollect', params: {id}}) :'已收藏店铺'
    }
  },
  watch: {
    storeId(val) {
      if (val && this.$check.trim(val+'').length > 0) this.query()
    }
  },
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/change.scss';
.bussineInfo {
  width: 232px;
  min-width: 232px;
}
.info {
  padding-bottom: 15px;
  border: 1px solid #d82229;
  text-align: center;
  .title {
    height: 40px;
    line-height: 40px;
    background-color: #d82229;
    color: white;
  }
  .content_1 {
    width: 100%;
    padding: 15px;
    margin: 0 auto;
    text-align: left;
    box-sizing: border-box;

    .company {

      font-size: 14px;
      font-weight: 500;
      margin-top: 15px;
      a {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url('../../assets/image/zaixiangoutong.png') no-repeat center;
        vertical-align: top;
        margin-left: 10px;
      }
      span{
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .btn {
      margin-top: 20px;
      cursor: pointer;
      > span {

        padding: 5px 10px;
        font-size: 14px;
        &:first-child {
          color: white;
          background-color: #d82229;
          border: 1px solid #d82229;
        }
        &:last-child {
          border: 1px solid #d82229;
          box-sizing: border-box;
          color: #d82229;
          margin-left: 30px;
        }
        &:hover{
          background-color: #d82229;
          color: #ffffff;
        }
      }
    }
  }
  h4 {
    margin: 10px 0;
    margin-top: 20px;
  }
  .phone {
    max-width: 150px;
    padding: 10px 20px;
    background-color: #ff9600;
    margin: 0 auto 20px;
    border-radius: 20px;
    color: white;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    a {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url('../../assets/image/dianhualianxiicon.png') no-repeat center;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}
.callBox {
  width: 90%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  span {
    display: inline-block;
  }
  .callRight {
    margin-left: 10px;
    display: inline-block;
    border: 1px solid #d82229;
    background-color: #bdf1da8c;
    padding: 3px 10px;
    box-sizing: border-box;
    cursor: pointer;
    a{
      text-decoration: none;
      margin-left: 5px;
    }
  }
}

//以下弹窗样式
/deep/ .el-dialog{
  width:440px;
  height:220px;
  margin-top:40vh !important;
  .el-dialog__body{
    padding:0;
  }
  .el-dialog__header{
      background: #f5f5f5;
  }
  .popTop{
    height:120px;
    .storeName{
      font-size:22px;
      color:#333;
      height:44px;
      line-height:44px;
      background: #f5f5f5;
    }
    .storeMobile{
      height:66px;
      line-height: 66px;
      font-size:28px;
      color:#333;
     background: #f5f5f5;
      >div{
        width:280px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin:0 auto;
        padding:0 10px;
        overflow: hidden;
        color:#333333;
        img{
          display: block;
        }
      }
      .streFooter{
        height: 80px;
        font-size: 12px;
        color: #999;
        display: flex;
        justify-content: center;
        line-height: 24px;
      }
    }
  }

}


.adressa{
  display: block;
  width:200px;
  text-align: left;
  padding:0 15px;
  padding-left: 0;
  // overflow:hidden;
  // color: #666;
  // text-overflow:ellipsis;white-space:nowrap;
  margin: 3px 0;
}
h4{
  color: #333;
  font-weight: 100;
  font-size: 16px;
}
</style>


