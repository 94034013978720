<template>
    <div class="demandDetail">
        <div class="nav">
            <p>您的位置>个人中心>我的比价>比价详情</p>
        </div>
        <div class="content">
            <div class="bg1">
                {{changeType(arr.state)}}
            </div>
            <div class="main">
                <div class="title">
                    <h2>{{arr.title}}</h2>
                    <div class="title-bottom">
                        <div class="title-bottom-l">
                            <span>需求编号:</span>
                            <span>{{arr.needsNo}}</span>
                        </div>
                        <div class="title-bottom-r">
                            <span class="title-bottom-r-1">发布时间:</span>
                            <span>{{arr.createDate}}</span>
                        </div>
                    </div>
                </div>

                <div class="info">
                    <h3>需求信息</h3>
                    <div class="info-top">
                        <p>
                            <span>需求预算：</span>
                            <span>{{arr.budgetAmount}}元</span>
                        </p>
                        <!--      <p>
                                  <span>剩余天数：</span>
                                  <span>{{arr.residueDate}}</span>
                                  </p>-->
                        <p>
                            <span>联系人：</span>
                            <span>{{arr.linkman}}</span>
                        </p>
                        <p>
                            <span>联系电话：</span>
                            <span>{{arr.mobile}}</span>
                        </p>
                    </div>

                    <div class="info-bottom">
                        <div class="info-bottom-l">截止时间:{{arr.endDate}}</div>
                        <div class="info-bottom-r">{{bidCon1.address}}</div>
                    </div>
                </div>

                <div class="description">
                    <h3>需求描述</h3>
                    <div class="node">
                        {{ content }}
                        <span
                                @click="handleFold(false)"
                                v-show="maxLen  != haystack.length"
                                v-if="flag"
                        >展开&or;</span>
                        <span @click="handleFold(true)" v-show="maxLen  == haystack.length">收起</span>
                    </div>
                </div>

                <div class="info quote-info">
                    <h3>报价信息</h3>
                    <div class="info-top">
                        <p>
                            <span>报价商家：</span>
                            <span>{{bidOffer.storeShtName}}</span>
                        </p>
                        <p>
                            <span>报价时间：</span>
                            <span>{{bidOffer.priceDate }}</span>
                        </p>
                        <p>
                            <span>报价金额：</span>
                            <span class="price">{{bidOffer.price | price}}元</span>
                        </p>
                        <p>
                            <span>需要工期：</span>
                            <span>{{bidOffer.timesize}}天</span>
                        </p>
                    </div>

                    <div class="info-bottom">
                        <div class="info-bottom-l info-bottom-l-quote">
                            {{bidCon2.address}}&nbsp&nbsp&nbsp{{bidCon2.detailAddress}}
                        </div>
                    </div>

                    <div class="node1">
                        {{ arr.requirementDesc }}
                        <span
                                @click="handleFold1(false)"
                                v-show="maxLen1  != haystack1.length"
                                v-if="flag1"
                        >展开&or;</span>
                        <span @click="handleFold1(true)" v-show="maxLen1  == haystack1.length">收起</span>
                    </div>
                </div>
            </div>

            <div class="side">
                <h3>参与报价商家</h3>
                <BusinessInfo :storeId="storeId"></BusinessInfo>
            </div>
            <div>
                <button v-on:click="hisChoice(bidOffer.bidId,bidOffer.storeId)" class="selectTa">选择他</button>
            </div>
        </div>
    </div>
</template>

<script>
    import hello from "../../../components/common/businessInfo";
    import * as api from '@/api/api';
    import Vue from "vue";
    import Header from "../../../components/common/header.vue";
    import Footer from "@/components/common/footer.vue";
    import Aside from "@/components/common/aside.vue";
    import BusinessInfo from "@/components/common/businessInfo";
    import Swiper from "swiper";
    // import "@/swiper/css/swiper.min.css";

    const maxLen = 130;
    const maxLen1 = 130;
    export default {
        name: "index",
        components: {Header, Footer, Aside, BusinessInfo}, //注册组件
        data() {
            return {
                haystack: "",
                storeId: this.$route.params.storeId,
                maxLen: maxLen,
                flag: true,
                bidCon1: {},//用户地址
                bidCon2: {},//商家地址
                bidOffer: {},//商家需求数据对象
                maxLen1: maxLen1,
                haystack1: "",
                flag1: true,
                arr: {}, //接口数据集合

                requirementDesc: "" //详情展示文字
            };
        },
        computed: {
            content() {
                const maxLen = this.maxLen; //120
                const haystack = this.haystack.toString(); //原本字符长度
                if (haystack.length > maxLen) {
                    this.flag = true;
                    return haystack.slice(0, maxLen) + "...";
                } else {
                    this.flag = false;
                    return haystack;
                }
            },
            content1() {
                const maxLen1 = this.maxLen1; //120
                const haystack1 = this.haystack1.toString(); //原本字符长度
                if (haystack1.length > maxLen1) {
                    this.flag1 = true;
                    return haystack1.slice(0, maxLen1) + "...";
                } else {
                    this.flag1 = false;
                    return haystack1;
                }
            }
        },
        methods: {
            changeType(str) {
                //状态：0->待审核；1->已审核通过（招标中）；2审核不通过；3->已完成；4->已关闭
                return str == 0 ? "待审核" : str == 1 ? "招标中" : str == 2 ? "2审核不通过" : str == 3 ? "已完成" : str == 4 ? "已关闭" : "";
            },
            hisChoice(bidId, storeId) { //跳转到订单确认页
                this.$http.get("bidInfo/updateState?id=" + bidId + "&state=3&storeId=" + storeId).then(res => {
                    let code = res.data.code;
                    if (code === 0 || code === 200) {
                           this.$router.push({
                               path: ("/orderNeed"),
                               query: {bidId: bidId, storeId: storeId}
                           })
                    }
                });
            },
            needDetail() { //需求详情首次加载数据
                let id = this.$route.params.id;
                let storeId = this.$route.params.storeId;
                let params = {id: id, storeId: storeId}
                let _this = this;
                api.needViewQuotation(params).then(res => {

                    let arr = res.data.data.bidInfo;
                    let bidCon1 = res.data.data.bidCon1;
                    let bidOffer = res.data.data.bidOffer;
                    let bidCon2 = res.data.data.bidCon2;
                    _this.arr = arr; //左侧数据
                    _this.bidCon1 = bidCon1; //左侧数据
                    _this.bidOffer = bidOffer; //左侧数据
                    _this.bidCon2 = bidCon2; //左侧数据
                    this.requirementDesc = _this.arr.requirementDesc;
                    let str = this.requirementDesc;
                    console.log(_this.bidCon2)
                });
            },

            handleFold(fold) {
                this.maxLen = fold ? maxLen : this.haystack.length; //点击展开九让最大长度等于原先的字符长度
            },
            handleFold1(fold) {
                this.maxLen1 = fold ? maxLen1 : this.haystack1.length;
            },
            getData: function () {
                setTimeout((str) => {
                    this.haystack = this.requirementDesc;

                }, 1000);
            },
            getData1: function () {
                setTimeout(() => {
                    this.haystack1 =
                        "长方形天地盒礼品包装皮盒 可定制内托皮质礼品盒金色精品礼盒，长方形天地盒礼品包装皮盒 可定制内托皮质礼品盒金色精品礼盒，长方形天地盒礼品包装皮盒 可定制内托皮质长方形天地盒礼品包装皮盒 可定制色精形天地盒礼品包装皮盒 可定制内托盒金色";
                }, 1000);
            }
        },
        mounted() {
            this.getData();
            this.getData1();
            this.needDetail();
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../../assets/scss/common";

    * {
        margin: 0;
        padding: 0;
    }

    h2,
    ul,
    li {
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
    }

    li {
        list-style: none;
        border-bottom: 1px solid #ccc;
    }

    h2,
    h3 {
        font-weight: 100;
    }

    .nav {
        height: 44px;
        width: 1200px;
        margin: 0 auto;
        margin-top: 20px;
        color: #666;
        line-height: 44px;
        font-size: 14px;
    }

    .demandDetail {
        width: 100%;
        background: #f5f5f5;
        font-size: 15px;

        .content {
            position: relative;
            width: 1200px;
            height: 1340px;
            background: #fff;
            margin: 0 auto;
            overflow: hidden;

            .bg1 {
                width: 140px;
                height: 50px;
                line-height: 52px;
                color: #fff;
                background: #adacaa;
                font-size: 20px;
                text-align: center;
                border-radius: 30px;
                margin: 20px 0 40px -30px;
                padding-left: 15px;
            }

            .main {
                padding-left: 30px;

                .title {
                    width: 740px;
                    height: 100%;
                    color: #333;

                    h2 {
                        height: 26px;
                        line-height: 26px;
                        font-size: 24px;
                    }

                    .title-bottom {
                        height: 50px;
                        line-height: 50px;
                        color: #999;
                        font-size: 14px;

                        > div {
                            float: left;
                        }

                        .title-bottom-r-1 {
                            margin-left: 38px;
                        }
                    }
                }

                .info {
                    border-bottom: 1px solid #e5e5e5;
                    width: 740px;

                    h3 {
                        font-weight: 900;
                        font-size: 18px;
                        padding-left: 15px;
                        background: url("../../../assets/image/biaotijuxing.png") no-repeat 0 18px;
                        background-size: 4px 18px;
                        height: 54px;
                        line-height: 54px;

                    }

                    .info-top {
                        p {
                            height: 34px;
                            line-height: 34px;
                            font-size: 16px;

                            span {
                                &:first-child {
                                    color: #666;
                                }
                            }
                        }
                    }

                    .info-bottom {
                        width: 741px;
                        height: 46px;

                        > div {
                            padding-left: 24px;
                            height: 38px;
                            line-height: 38px;
                            color: #9c9c9c;
                            font-size: 14px;
                            margin-top: 7px;
                        }

                        :first-child {
                            float: left;
                            background: url("../../../assets/image/jiezhishijian.png") no-repeat 0 11px;
                            background-size: 15px 15px;
                        }

                        :last-child {
                            float: left;
                            margin-left: 31px;
                            background: url("../../../assets/image/dingwei.png") no-repeat 0 11px;
                            background-size: 15px 15px;
                        }

                        .info-bottom-l-quote {
                            margin-left: 0;
                        }
                    }
                }

                .quote-info {
                    margin-top: 49px;

                    .price {
                        color: #ff425e;
                    }
                }

                .description,
                .detail,
                .quote-info {
                    position: relative;
                    width: 710px;
                    height: 100%;

                    h3 {
                        font-weight: 900;
                        font-size: 18px;
                        padding-left: 15px;
                        background: url("../../../assets/image/biaotijuxing.png") no-repeat 0 18px;
                        background-size: 4px 18px;
                        height: 54px;
                        line-height: 54px;
                    }

                    .node,
                    .node1 {
                        width: 100%;
                        padding: 8px 10px;
                        padding-right: 20px;
                        height: 100%;
                        background: #f5f5f5;
                        line-height: 30px;

                        span {
                            color: #38be82;
                        }
                    }

                    .dropDown {
                        right: 0;
                        bottom: 0;
                        position: absolute;
                        right: -10px;
                        bottom: 5px;
                        color: #38be82;
                        cursor: pointer;
                    }
                }
            }

            .side {
                width: 220px;
                height: 446px;

                position: absolute;
                top: 35px;
                right: 60px;

                h3 {
                    font-size: 18px;
                    padding-left: 15px;
                    background-size: 4px 18px;
                    height: 38px;
                    line-height: 38px;
                    background: url("../../../assets/image/biaotijuxing.png") no-repeat 0 10px;
                    background-size: 4px 18px;
                }

                #bussineInfo {
                    width: 242px;
                    height: 405px;
                }
            }
        }
        .selectTa{
         width: 80px;
        height: 35px;
        cursor: pointer;
        font-size: 16px;
        padding: 5px;
        background: #0db168;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border:none;
        color: #fff;
        margin:20px 0 0 30px;
    }
    }

</style>
