export default {
  data() {
    return {
      location: {},
      timeStr: '',
      range: 1800
    };
  },
  mounted() {
  },
  methods: {
    /**
     * @description: 获取经纬度
     * @param {type}
     * @return:
     */
    getLocation() {
      AMap.plugin('AMap.Geolocation', function() {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
          timeout: 10000, //超过10秒后停止定位，默认：5s
          zoomToAccuracy: true //定位成功后是否自动调整地图视野到定位点
        });
        geolocation.getCurrentPosition(
          function(status, result) {
            console.log(result,status);
            if (status == 'complete') {
              console.log(result);
            } else {
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(position => {
                  console.log(position);
                });
              }
            }
          },
          error => {
            console.log(err);
          }
        );
      });
    },
    countDown() {
      let timer = setInterval(() => {
        if (this.range === 0) {
          clearInterval(timer);
          return;
        }
        this.range--;
        const min = parseInt(this.range / 60) > 9 ? parseInt(this.range / 60) : '0' + parseInt(this.range / 60)
        const sec = this.range % 60 > 9 ? this.range % 60 : '0' + this.range % 60
        this.timeStr =  min + '分' + sec + '秒';
      }, 1000);
    }
  }
};
